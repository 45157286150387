/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-named-as-default-member */
import type { InitOptions, Callback } from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store2';
// @ts-ignore
import resources from '~/locales';

const defaultLocale = 'zh-TW';

let locale = store.get('locale');
if (!locale) {
	store.set('locale', defaultLocale);
	locale = defaultLocale;
}

const initOptions: InitOptions = {
	resources,
	saveMissing: true,
	load: 'currentOnly',
	lng: locale,
	ns: [
		'common',
		'customers',
		'carriers',
		'orders',
		'orderDetail',
		'form',
		'dc',
		'dnDetail',
		'file',
		'dispatch',
		'shipmentRules',
		'uploadOrders',
		'containerOrder',
		'liff',
		'podReport',
		'orderReport',
		'pod',
		'users',
		'organizationRoles',
		'permission',
		'organizationUsers',
		'ticket',
		'operation',
		'ticketDetail',
	],
	defaultNS: 'common',
	fallbackLng: [],
	debug: process.env.NODE_ENV !== 'production',
	keySeparator: false,
	interpolation: {
		escapeValue: false,
		format(value, format) {
			if (value) {
				if (format === 'join') return value.join(', ');
			}
			return value;
		},
	},
};

export function loadI18n(callback: Callback) {
	i18n.use(initReactI18next).init(initOptions, callback);
}

export default i18n;
